import React from "react"
import Seo from "../components/seo"
import Box from "@material-ui/core/Box"
import { useMicroCopyFi } from "../hooks/microCopy"
import LayoutController from "../components/layoutController"
import GetMicroCopy from "../shared/getMicroCopy"

const NotFoundPage = () => {
  const microCopyTexts = useMicroCopyFi
  // UI texts
  const labelNotFoundTitle = GetMicroCopy(microCopyTexts, "404Otsikko")
  const labelNotFoundText = GetMicroCopy(microCopyTexts, "404Teksti")
  return (
    <LayoutController language="fi">
    <Box>
      <Seo
        seoTitle={labelNotFoundTitle}
        seoDesc={labelNotFoundText}
        url={typeof window !== "undefined" ? window.location.href : ""}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <h1>{labelNotFoundTitle}</h1>
          <p>{labelNotFoundText}</p>
        </Box>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default NotFoundPage
